import { create } from "zustand";
import { persist } from "zustand/middleware";

// Type of store
interface StatementStore {
	acceptAccuracyInformation: boolean;
	setAcceptAccuracyInformation: (acceptAccuracyInformation: boolean) => void;
	confirmAcceptUseCases: boolean;
	setConfirmAcceptUseCases: (confirmAcceptUseCases: boolean) => void;
	reset: () => void;
}

// Create store
const useStatementStore = create<StatementStore>()(
	persist(
		(set) => ({
			acceptAccuracyInformation: false,
			setAcceptAccuracyInformation: (acceptAccuracyInformation: boolean) =>
				set({ acceptAccuracyInformation }),
			confirmAcceptUseCases: false,
			setConfirmAcceptUseCases: (confirmAcceptUseCases: boolean) =>
				set({ confirmAcceptUseCases }),
			reset: () =>
				set({
					acceptAccuracyInformation: false,
					confirmAcceptUseCases: false,
				}),
		}),
		{
			name: "statement-storage",
		},
	),
);

export default useStatementStore;
