import { useEffect, useState } from "react";
import CustomCard from "@/components/CardComponent/CustomCard";
import { MobileFooter } from "@/components/MobileComponents/MobileFooter";
import { useCustomNavigate } from "@/hooks/customNavigate";
import assuredImage from "@/pages/3-SimulationAndGuaranteesPage/assets/assured.svg";
import useProgressStore from "@/stores/useProgressStore";

import SimulationCardContent from "./SimulationCardContent";

enum NavigationAction {
	Next = "Next",
	Back = "Back",
}

interface SimulationAndGuaranteesPageProps {
	stepProps: number;
}

export const SimulationAndGuaranteesPage = ({
	stepProps,
}: SimulationAndGuaranteesPageProps) => {
	const navigate = useCustomNavigate();
	const { incrementStep, decrementStep, setStep, setLastSubmittedStep } =
		useProgressStore((state) => ({
			incrementStep: state.incrementStep,
			decrementStep: state.decrementStep,
			setStep: state.setStep,
			setLastSubmittedStep: state.setLastSubmittedStep,
		}));
	const [isDisplayFooter, setIsDisplayFooter] = useState(true);

	const handleNext = (str: NavigationAction) => {
		if (str === NavigationAction.Next) {
			incrementStep();
			navigate("COMPANY_SIREN");
			setLastSubmittedStep(stepProps);
		} else {
			navigate("TURNOVER");
			decrementStep();
		}
	};

	const handleBack = () => {
		navigate("TURNOVER");
	};

	useEffect(() => {
		setStep(stepProps);
	}, []);

	return (
		<div className="flex w-screen flex-1 flex-row items-center">
			<div className="hidden h-full w-full flex-1 flex-col justify-center lg:flex">
				<img src={assuredImage} className="h-[70vh] w-[40vw]" />
			</div>
			<div className="flex h-full w-full flex-1 items-center justify-center lg:mr-5">
				<CustomCard
					className={"h-auto max-h-full lg:max-h-[95vh]"}
					displayFooter={isDisplayFooter}
					onButtonClick={handleNext}
					handleBack={handleBack}
				>
					<SimulationCardContent hideFooter={setIsDisplayFooter} />
				</CustomCard>
			</div>
			<MobileFooter onButtonClick={handleNext} />
		</div>
	);
};
