import { useState } from "react";
import { AddressInput } from "@/components/shared/AdressInput";
import { CheckBoxWithLabel } from "@/components/shared/CheckBoxWithLabel";
import { PostalCodeInput } from "@/components/shared/PostalCodeInput";
import TextInput from "@/components/shared/TextInput";
import { CardContent, CardHeader } from "@/components/ui/card";
import useSubscriberInfoStore from "@/stores/useSubscriberInfoStore";
import { cn } from "@/utils";

import type { CreateSubscriberInfoRequestDto } from "@repos/ecom-dtos";

export default function SubscriberInfosCardContent() {
	const {
		isCheckedPolicyAndUseCases,
		setIsCheckedPolicyAndUseCases,
		isCheckedUpdateAndPromotionnalOffers,
		setIsCheckedUpdateAndPromotionnalOffers,
	} = useSubscriberInfoStore((state) => ({
		isCheckedPolicyAndUseCases: state.isCheckedPolicyAndUseCases,
		setIsCheckedPolicyAndUseCases: state.setIsCheckedPolicyAndUseCases,
		isCheckedUpdateAndPromotionnalOffers:
			state.isCheckedUpdateAndPromotionnalOffers,
		setIsCheckedUpdateAndPromotionnalOffers:
			state.setIsCheckedUpdateAndPromotionnalOffers,
	}));

	const toggleUseCase = () => {
		setIsCheckedPolicyAndUseCases(!isCheckedPolicyAndUseCases);
	};

	const [isManualMode, setIsManualMode] = useState(false);

	return (
		<>
			<CardHeader className="mt-0 flex flex-grow items-center justify-start px-0 lg:px-6 xl:mt-8">
				<div className="flex w-full  flex-col items-center justify-center gap-4">
					<div className="relative   w-full">
						{isManualMode ? (
							<TextInput<CreateSubscriberInfoRequestDto>
								name="adressSubscriber"
								label="Adresse"
								placeholder=""
								inputClassName={
									"h-16 lg:h-11 xl:h-16 w-full xl:w-full justify-start lg:justify-center truncate"
								}
								labelClassName="bg-white rounded-full text-xs text-mobileBlack lg:text-blue-500"
								enableInput={true}
							/>
						) : (
							<AddressInput<CreateSubscriberInfoRequestDto>
								name="adressSubscriber"
								label="Adresse"
								cityProps={{ name: "city", label: "Ville" }}
								countryProps={{ name: "country", label: "Pays" }}
								postalCodeProps={{ name: "postalCode", label: "Code postal" }}
								inputClassName={
									"h-16 lg:h-11 xl:h-16 w-full xl:w-full justify-start lg:justify-center truncate"
								}
								labelClassName="bg-white rounded-full text-xs text-mobileBlack lg:text-blue-500"
								placeholder=""
							/>
						)}
					</div>
					<div className="flex w-full flex-col-reverse gap-4 lg:flex-col">
						<div className=" flex w-full flex-row items-center justify-between gap-x-2">
							<div className="min-w-32 flex-[1]">
								<TextInput<CreateSubscriberInfoRequestDto>
									name="country"
									placeholder=""
									label="Pays"
									enableInput={isManualMode}
									labelClassName="bg-white rounded-full text-xs text-mobileBlack lg:text-blue-500"
									inputClassName={cn(
										`h-16 bg-white lg:h-11 xl:h-16`,
										isManualMode ? "bg-white" : "bg-gray-700",
									)}
								/>
							</div>
							<div className="min-w-40 flex-1">
								<PostalCodeInput<CreateSubscriberInfoRequestDto>
									labelClassName="bg-white rounded-full text-xs text-mobileBlack lg:text-blue-500"
									inputClassName={cn(
										`h-16 bg-white lg:h-11 xl:h-16`,
										isManualMode ? "bg-white" : "bg-gray-700",
									)}
									cityProps={{ name: "city", label: "Ville" }}
									label="Code postal"
									name="postalCode"
									placeholder=""
									enableInput={!isManualMode}
								/>
							</div>
						</div>
						<div className="relative   w-full">
							<TextInput<CreateSubscriberInfoRequestDto>
								name="city"
								placeholder=""
								enableInput={isManualMode}
								label="Ville"
								labelClassName="bg-white rounded-full text-xs text-mobileBlack lg:text-blue-500"
								inputClassName={cn(
									`h-16 w-full bg-white lg:h-11 xl:h-16 xl:w-full`,
									isManualMode ? "bg-white" : "bg-gray-700",
								)}
							/>
						</div>
					</div>
				</div>
				<div>
					<CheckBoxWithLabel
						label="Je saisie manuellement mon adresse"
						onClickCheckBox={() => setIsManualMode(!isManualMode)}
						isChecked={isManualMode}
						inputRadioGroup="size-4 h-4"
					/>
				</div>
			</CardHeader>
			<CardContent className="mt-0 flex flex-grow items-start justify-start px-0 pb-0 lg:px-6">
				<div className="flex w-full flex-col items-center justify-center">
					<CheckBoxWithLabel
						isChecked={isCheckedUpdateAndPromotionnalOffers}
						onClickCheckBox={setIsCheckedUpdateAndPromotionnalOffers}
						label="J'accepte de recevoir des mises à jour et des offres promotionnelles"
					/>
					<CheckBoxWithLabel
						isChecked={isCheckedPolicyAndUseCases}
						onClickCheckBox={toggleUseCase}
					/>
				</div>
			</CardContent>
		</>
	);
}
