import IconChatbot from "@/assets/IconChatbot.svg";
import LogoAssurThech from "@/assets/LogoAssurThec.svg";
import { MobileHeader } from "@/components/MobileComponents/MobileHeader";
import Stepper from "@/components/StepperComponent/Stepper";
import { Button } from "@/components/ui/button";
import { Toaster } from "@/components/ui/toaster";
import { Outlet } from "react-router-dom";

const Layout = () => {
	return (
		<div className="flex h-full flex-col gap-6">
			<div className="flex flex-col lg:h-full">
				<div className="sticky left-0 right-0 top-0 z-50 hidden h-28 w-full flex-shrink-0 flex-col items-center justify-evenly gap-4 bg-white p-5 shadow-sm lg:flex lg:h-16 lg:flex-row lg:gap-0 xl:h-20">
					<img src={LogoAssurThech} alt="Logo" className="h-auto w-32" />
					<Stepper />
				</div>
				<MobileHeader />
				<main className="flex-1 py-9">
					<div className="pb-32 lg:flex lg:h-full lg:items-center lg:justify-center lg:pb-0">
						<Outlet />
					</div>
				</main>
				<Toaster />
				<Button
					variant={"suivant"}
					className="fixed bottom-[10%] right-4 hidden h-14 w-14 cursor-pointer items-center justify-center rounded-full bg-blue-500 text-white lg:flex"
				>
					<img src={IconChatbot} alt="Chatbot" />
				</Button>
			</div>
		</div>
	);
};

export default Layout;
