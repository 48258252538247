import { PostalCodeInput } from "@/components/shared/PostalCodeInput";
import SelectInput from "@/components/shared/SelectInput";
import TextInput from "@/components/shared/TextInput";
import { CardContent } from "@/components/ui/card";
import { ManualMode } from "@/pages/4-CompanySIRENPage";
import useCompanyStore from "@/stores/useCompanyStore";
import { cn } from "@/utils";

import type { CompanyDto } from "@repos/ecom-dtos";
import { LegalStatus } from "@repos/ecom-dtos";

import { AddressInput } from "../../../components/shared/AdressInput";

interface SignificantSirenTargetCardProps {
	isManualMode: ManualMode;
}

export default function SignificantSirenTargetCard({
	isManualMode,
}: SignificantSirenTargetCardProps) {
	const { isSirenOptional } = useCompanyStore((state) => ({
		isSirenOptional: state.isSirenOptional,
	}));

	const getInputClassName = (isDisabled: boolean) =>
		cn(
			`h-16 w-full justify-start truncate xl:h-16 xl:w-full`,
			isDisabled ? "cursor-not-allowed bg-gray-700 opacity-50" : "bg-white",
		);

	const getEnableInput = (mode: ManualMode) =>
		mode !== ManualMode.AllDisabled && mode !== ManualMode.AlwaysDisabled;

	const isInputEnabled = getEnableInput(isManualMode);
	const isAddressInputEnabled =
		isManualMode === ManualMode.IsManual ||
		isManualMode === ManualMode.AlwaysDisabled;
	const isIndividualInputEnabled = !isAddressInputEnabled;

	const legalStatusOptions = Object.entries(LegalStatus).map(
		([key, value]) => ({
			value,
			label: key,
		}),
	);

	return (
		<CardContent className="mt-0 flex flex-grow items-start justify-center px-0 md:mt-6 lg:px-6">
			<div className="mt-0 flex w-full flex-col items-center justify-center gap-y-4 md:mt-3">
				<p className="flex text-left text-lg font-bold text-blackMobile md:hidden">
					Remplissez vos informations de l’entreprise
				</p>
				<TextInput<CompanyDto>
					name="name"
					placeholder=""
					enableInput={isInputEnabled}
					label="Nom de votre société"
					labelClassName="bg-white rounded-full text-xs top-0"
					inputClassName={getInputClassName(!isInputEnabled)}
				/>
				<div className="flex w-full flex-row items-center justify-between gap-x-2">
					<div className="min-w-32 flex-[0.6]">
						<TextInput<CompanyDto>
							name="codeNaf"
							placeholder=""
							label="Code NAF"
							enableInput={isInputEnabled}
							labelClassName="bg-white rounded-full text-xs"
							inputClassName={getInputClassName(!isInputEnabled)}
							required={!isSirenOptional}
						/>
					</div>
					<div className="min-w-40 flex-1">
						{isManualMode === ManualMode.AllDisabled ||
						isManualMode === ManualMode.AlwaysDisabled ? (
							<TextInput<CompanyDto>
								name="legalStatusLabel"
								placeholder=""
								label="Statut juridique"
								enableInput={isInputEnabled}
								labelClassName="bg-white rounded-full text-xs"
								inputClassName={getInputClassName(!isInputEnabled)}
							/>
						) : (
							<SelectInput<CompanyDto>
								name="legalStatusLabel"
								placeholder=""
								label="Statut juridique"
								enableSelect={true}
								labelClassName="bg-white rounded-full text-xs"
								selectClassName="h-16"
								options={legalStatusOptions}
							/>
						)}
					</div>
				</div>

				<AddressInput<CompanyDto>
					name="address"
					label="Adresse siège social"
					cityProps={{ name: "city", label: "Ville" }}
					postalCodeProps={{ name: "postalCode", label: "Code postal" }}
					inputClassName={getInputClassName(!isInputEnabled)}
					labelClassName="bg-white rounded-full text-xs"
					placeholder=""
					enableInput={isAddressInputEnabled}
				/>
				<div className="flex w-full flex-row items-center justify-between gap-x-2">
					<PostalCodeInput<CompanyDto>
						labelClassName=""
						inputClassName={getInputClassName(!isInputEnabled)}
						cityProps={{ name: "city", label: "Ville" }}
						label="Code postal"
						name="postalCode"
						placeholder=""
						enableInput={isIndividualInputEnabled}
					/>
					<TextInput<CompanyDto>
						name="city"
						label="Ville"
						enableInput={isAddressInputEnabled}
						inputClassName={getInputClassName(!isInputEnabled)}
						placeholder=""
					/>
				</div>
			</div>
		</CardContent>
	);
}
