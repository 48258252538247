import { useCallback, useEffect, useState } from "react";
import MobileCardBasicGuaranteeService from "@/components/MobileComponents/MobileCardBasicGuaranteeService";
import { MobileFooter } from "@/components/MobileComponents/MobileFooter";
import { CalendarDatePicker } from "@/components/shared/CalendarDatePicker";
import { Skeleton } from "@/components/ui/skeleton";
import { useCustomNavigate } from "@/hooks/customNavigate";
import { useDatePickerLogic } from "@/hooks/useDatePickerLogic";
import { useMonthlyPrime } from "@/hooks/useMonthlyPrice";
import CardBasicGuaranteeService from "@/pages/8-EffectiveContractDatePage/components/CardBasicGuaranteeService";
import usePricingStore from "@/stores/usePricingStore";
import useProgressStore from "@/stores/useProgressStore";
import useTurnoverStore from "@/stores/useTurnoverStore";
import { trpc } from "@/utils";
import { convertToUtc, getMaxSelectableDate } from "@/utils/dateUtlis";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import type { EffectiveDateDto } from "@repos/ecom-dtos";
import { effectiveDateSchema } from "@repos/ecom-dtos";

import CardFinalGuarantees from "./components/CardFinalGuarantees";
import CardOptionnalGuaranteeService from "./components/CardOptionnalGuaranteeServices";

interface EffectiveContractDatePageProps {
	stepProps: number;
}

export const EffectiveContractDatePage = ({
	stepProps,
}: EffectiveContractDatePageProps) => {
	const navigate = useCustomNavigate();
	const { setStep, setLastSubmittedStep } = useProgressStore((state) => ({
		incrementStep: state.incrementStep,
		setStep: state.setStep,
		setLastSubmittedStep: state.setLastSubmittedStep,
	}));
	const { turnover } = useTurnoverStore((state) => ({
		turnover: state.turnover,
	}));

	const {
		setBasicPricing,
		effectiveDate,
		setEffectiveDate,
		setMonthlyPrime,
		basicCeilings,
		updateBasicPrice,
	} = usePricingStore((state) => ({
		setMonthlyPrime: state.setMonthlyPrime,
		setBasicPricing: state.addBasicCeiling,
		setEffectiveDate: state.setEffectiveDate,
		effectiveDate: state.effectiveDate,
		basicCeilings: state.basicCeilings,
		optionalCeilings: state.optionalCeilings,
		updateBasicPrice: state.updateBasicPrice,
	}));

	useEffect(() => {
		if (!turnover) {
			navigate("TURNOVER");
		}
	}, [turnover, navigate]);

	const effectiveDateDefaultDate = new Date(effectiveDate.effectiveDate);

	const {
		selectedYear,
		selectedMonth,
		isDisplayedPopoverContent,
		setIsDisplayedPopoverContent,
		minSelectableDate,
		maxSelectableDate,
		handleYearChange,
		handleMonthChange,
	} = useDatePickerLogic({
		type: "effective",
		defaultDate: effectiveDateDefaultDate,
	});

	const { data: guarantees, isLoading } =
		trpc.guarantees.getAllGurantees.useQuery(
			{ turnover: turnover ?? 0 },
			{ enabled: turnover ? true : false },
		);

	const allBasicsGuarantees = guarantees?.fullGuarantees.filter(
		(item) => !item.isOptional && item.type !== "AP",
	);

	const allOptionalGuarantees = guarantees?.fullGuarantees.filter(
		(item) => item.isOptional,
	);

	const listLabelBasicGuarantees = guarantees?.fullGuarantees.filter(
		(item) => !item.isOptional,
	);

	const [sliderValue, setSliderValue] = useState([200000]);

	useEffect(() => {
		if (basicCeilings[0]?.ceilingValue) {
			setSliderValue([basicCeilings[0]?.ceilingValue]);
		}
	}, [basicCeilings]);

	const calculateInitialBasicPrice = (value: number[]) => {
		let totalResult: number = 0;

		allBasicsGuarantees?.forEach((basicGuarantee) => {
			let result: number = 0;

			basicGuarantee.ceilings.forEach((item) => {
				if (value[0] === item.ceilingValue) {
					result += (item.priceBeforeTax + item.taxValue) / 12;
				}
			});

			if (value[0]) {
				setBasicPricing({
					label: basicGuarantee.label,
					type: basicGuarantee.type,
					ceilingValue: value[0],
					franchise: basicGuarantee.franchiseValue,
					guaranteeIcon: basicGuarantee.activeIconUrl,
					ceilingOptions: [],
				});
			}
			totalResult += result;
		});

		return totalResult;
	};

	const handleSliderChange = useCallback(
		(value: number[]) => {
			setSliderValue(value);
			updateBasicPrice(calculateInitialBasicPrice(value));
		},
		[allBasicsGuarantees, setBasicPricing],
	);

	const { monthlyPrimeTotal } = useMonthlyPrime();

	const methods = useForm<EffectiveDateDto>({
		defaultValues: {
			effectiveDate: new Date(effectiveDate.effectiveDate),
		},
		resolver: zodResolver(effectiveDateSchema),
		mode: "onSubmit",
	});

	const onSubmit = (data: EffectiveDateDto) => {
		setEffectiveDate({ effectiveDate: convertToUtc(data.effectiveDate) });
		setMonthlyPrime(monthlyPrimeTotal);
		navigate("SUMMARIZE_OFFER");
		setLastSubmittedStep(stepProps);
	};

	useEffect(() => {
		calculateInitialBasicPrice(sliderValue);
	}, [guarantees]);

	useEffect(() => {
		setStep(stepProps);
	}, []);

	if (isLoading) {
		return (
			<div className="flex w-full flex-col px-7 lg:mx-5 lg:flex-row lg:px-0 lg:pl-10">
				<div className="flex h-full w-full flex-col items-center justify-start space-y-4 lg:w-7/12">
					<Skeleton className="h-[10rem] w-full rounded-lg bg-gray-300 lg:h-[15rem]" />
					<Skeleton className="h-[15rem] w-full rounded-lg bg-gray-300" />
					<Skeleton className="h-[15rem] w-full rounded-lg bg-gray-300" />
					<Skeleton className="h-[15rem] w-full rounded-lg bg-gray-300" />
					<Skeleton className="h-[15rem] w-full rounded-lg bg-gray-300" />
				</div>
				<div className="mr-0 hidden h-full w-full items-start justify-center lg:mr-5 lg:flex lg:w-5/12">
					<Skeleton className=" h-[30rem] w-[80%] rounded-lg rounded-tl-custom  bg-gray-300" />
				</div>
			</div>
		);
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<div className="mx-5 flex flex-col lg:flex-row lg:px-0 lg:pl-14">
					<div className="flex h-full w-full flex-col items-center justify-start lg:w-7/12">
						<div className="mb-5 flex w-full flex-col items-start">
							<p className="text-left text-lg font-bold text-blackMobile lg:text-xl lg:text-gray-800">
								Choisissez votre date d&apos;effet
							</p>
						</div>
						<div className="flex w-full flex-col items-start">
							<div className="relative mx-auto w-4/5 lg:mx-0 lg:w-3/5">
								<CalendarDatePicker<EffectiveDateDto>
									name="effectiveDate"
									label="Date d’effet"
									placeHolder="jj/mm/aaaa"
									type="effective"
									toYear={getMaxSelectableDate(90).getFullYear()}
									minSelectableDate={minSelectableDate}
									maxSelectableDate={maxSelectableDate}
									selectedYear={selectedYear}
									selectedMonth={selectedMonth}
									onYearChange={handleYearChange}
									onMonthChange={handleMonthChange}
									labelClassName="text-xs md:text-sm left-2 md:left-6 text-mobileBlack md:text-blue-500"
									isDisplayedPopoverContent={isDisplayedPopoverContent}
									setIsDisplayedPopoverContent={setIsDisplayedPopoverContent}
									inputClassName="bg-white h-16 lg:h-20"
									tooltipLabel="La date d'effet doit être strictement supérieure à la date de souscription et ne dépassant pas les 90 jours."
								/>
							</div>
						</div>
						<div className="mt-11 flex w-full flex-col items-start">
							<p className="mb-5 text-left text-lg font-bold text-blackMobile lg:text-xl lg:text-gray-800">
								Explorez vos garanties de base
							</p>
						</div>
						{allBasicsGuarantees &&
							allBasicsGuarantees.map((guarantee, index) => (
								<>
									{/* Mobile version */}
									<MobileCardBasicGuaranteeService
										key={index}
										guarantee={guarantee}
										colorValueNoTselected={
											guarantee.type === "RC"
												? "paleYellow-foreground"
												: guarantee.type === "PJ"
													? "paleBlue-foreground"
													: ""
										}
										displayValue={sliderValue}
										handleValueOnSlide={handleSliderChange}
									/>
									{/* Desktop version */}
									<CardBasicGuaranteeService
										key={guarantee.order}
										guarantee={guarantee}
										colorValueNoTselected={
											guarantee.type === "RC"
												? "paleYellow-foreground"
												: guarantee.type === "PJ"
													? "paleBlue-foreground"
													: ""
										}
										colorValueSelected={
											guarantee.type === "RC"
												? "paleYellow"
												: guarantee.type === "PJ"
													? "paleBlue"
													: ""
										}
										displayValue={sliderValue}
										handleValueOnSlide={handleSliderChange}
									/>
								</>
							))}
						<div className="mt-12 flex w-full flex-col items-start">
							{/* Desktop version */}
							<p className="mb-5 hidden text-left text-xl font-bold text-gray-800 lg:flex">
								Vous pourriez également bénéficier du
							</p>
							{/* Mobile version */}
							<p className="mb-5 text-left text-lg font-bold text-blackMobile lg:hidden">
								Vous pourriez sélectionnez des garanties de plus :
							</p>
						</div>
						{allOptionalGuarantees &&
							allOptionalGuarantees.map((guarantee) => (
								<CardOptionnalGuaranteeService
									key={guarantee.type}
									guarantee={guarantee}
									colorValueSelected={
										guarantee.type === "RCE"
											? "lightRed"
											: guarantee.type === "TDMI"
												? "lightGreen"
												: guarantee.type === "CYBER"
													? "bluePastel"
													: ""
									}
									colorValueNoTselected={
										guarantee.type === "RCE"
											? "lightRed-foreground"
											: guarantee.type === "TDMI"
												? "lightGreen-foreground"
												: guarantee.type === "CYBER"
													? "bluePastel-foreground"
													: ""
									}
								/>
							))}
					</div>
					<div className="sticky top-20 mr-0 hidden h-full w-full items-start justify-center lg:mr-5 lg:flex lg:w-5/12">
						<CardFinalGuarantees
							onClickSubscribe={onSubmit}
							listLabelBasicGuarantees={listLabelBasicGuarantees || []}
						/>
					</div>
				</div>
				<MobileFooter label="Valider" />
			</form>
		</FormProvider>
	);
};
