import type { ClassValue } from "clsx";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/utils";

type CheckBoxWithLabelProps = {
	onClickCheckBox: (value: boolean) => void;
	isChecked?: boolean | null;
	label?: string;
	inputRadioGroup?: string | undefined;
	disabledOptions?: boolean;
	checkedClass?: string;
	checkedBgClass?: string;
	id?: string;
	labelClassNames?: ClassValue;
};

export const CheckBoxWithLabel = ({
	onClickCheckBox,
	isChecked,
	label,
	inputRadioGroup,
	disabledOptions,
	checkedClass,
	checkedBgClass,
	id,
	labelClassNames,
}: CheckBoxWithLabelProps) => {
	return (
		<RadioGroup
			defaultValue="comfortable"
			className="m-2 flex w-full items-center justify-start self-start rounded-full"
		>
			<div className="flex items-center space-x-2 lg:items-start">
				<RadioGroupItem
					disabled={disabledOptions}
					onClick={() => onClickCheckBox(!isChecked)}
					className={cn(
						`h-4 border-[0.06rem] border-blue-500`,
						isChecked
							? `border-blue-500 bg-blue-500 text-white 
							${checkedClass}`
							: `border-gray-300 bg-white text-blue-500`,
						inputRadioGroup,
					)}
					value={"default"}
					checked={isChecked ? isChecked : false}
					id={id}
					style={{
						backgroundColor: isChecked ? `${checkedBgClass}` : "",
					}}
				/>

				{label !== undefined ? (
					label ? (
						<Label
							htmlFor={id}
							className={cn("text-xs font-normal xl:text-sm", labelClassNames)}
						>
							{label}
						</Label>
					) : null
				) : (
					<Label htmlFor={id} className="text-xs font-normal xl:text-sm">
						<span>J&apos;accepte</span>
						<span className="mx-1 cursor-pointer text-primary underline">
							la politique de confidentialité
						</span>
						<span>et</span>
						<span className="mx-1 cursor-pointer text-primary underline">
							les conditions générales.
						</span>
					</Label>
				)}
			</div>
		</RadioGroup>
	);
};
