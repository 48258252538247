import { ActivityField } from "@prisma/client";
import { z } from "zod";

import {
	addressSchema,
	citySchema,
	companyNafSchema,
	companyNameSchema,
	legalStatusSchema,
	postalCodeSchema,
	sirenSchema,
} from "../primitives";

export const CreateCompanyRequestSchema = z.object({
	turnover: z.number().positive(),
	sirenNumber: z.string(),
	name: z.string(),
	legalStatusCode: z.string(),
	legalStatusLabel: z.string(),
	mainActivityCode: z.string(),
	mainActivityLabel: z.string(),
	commune: z.string(),
	siret: z.string(),
	// companyCategory: z.string(),
	activityField: z.nativeEnum(ActivityField),
});

export type CreateCompanyRequestDto = z.infer<
	typeof CreateCompanyRequestSchema
>;

export const companySchema = z.object({
	sirenNumber: sirenSchema,
	name: companyNameSchema,
	legalStatusLabel: legalStatusSchema,
	legalStatusCode: z.string(),
	mainActivityCode: z.string(),
	mainActivityLabel: z.string(),
	commune: z.string(),
	siret: z.string(),
	codeNaf: companyNafSchema,
	address: addressSchema,
	city: citySchema,
	postalCode: postalCodeSchema,
});

export const companySchemaRequired = companySchema.extend({
	sirenNumber: sirenSchema,
	codeNaf: companyNafSchema,
});

export const companySchemaOptional = companySchema.extend({
	sirenNumber: z.string().optional(),
	codeNaf: z.string().optional(),
});

export const restCompanySchemaOptional = companySchema.extend({
	sirenNumber: sirenSchema,
	codeNaf: z.string().optional(),
	address: z.string().optional(),
	city: z.string().optional(),
	postalCode: z.string().optional(),
	name: z.string().optional(),
	legalStatusLabel: z.string().optional(),
});

export type CompanyDto = z.infer<typeof companySchema>;

export const CreateCompanyResponseSchema = z.object({
	id: z.string(),
	turnover: z.number().positive(),
	sirenNumber: z.string(),
	name: z.string(),
	legalStatusCode: z.string(),
	legalStatusLabel: z.string(),
	mainActivityCode: z.string(),
	mainActivityLabel: z.string(),
	commune: z.string(),
	siret: z.string(),
	activityField: z.nativeEnum(ActivityField),
	addressId: z.string(),
	subscriptionId: z.string(),
});

export type CreateCompanyResponseDto = z.infer<
	typeof CreateCompanyResponseSchema
>;

export const CompanyInfoBySirenSchema = z.object({
	siren: z.string().nullable(),
	completeName: z.string(),
	legalName: z.string().nullable(),
	headquarters: z.object({
		address: z.string(),
		detailedAddress: z
			.object({
				streetLabel: z.string().nullable(),
				postalCode: z.string().nullable(),
				city: z.string().nullable(),
				country: z.string().nullable(),
			})
			.default({
				streetLabel: null,
				postalCode: null,
				city: null,
				country: null,
			}),
		postalCode: z.string().nullable(),
		commune: z.string().nullable(),
		siret: z.string(),
	}),
	mainActivity: z
		.object({
			code: z.string(),
			label: z.string(),
		})
		.default({
			code: "",
			label: "",
		}),
	companyCategory: z.string().nullable(),
	administrativeStatus: z.string(),
	legalStatus: z
		.object({
			code: z.string(),
			label: z.string(),
		})
		.default({
			code: "",
			label: "",
		}),
	mainActivitySection: z.string(),
	diffusionStatus: z.string(),
});

export type CompanyInfoBySirenDto = z.infer<typeof CompanyInfoBySirenSchema>;

export enum LegalStatus {
	SARL = "SARL",
	EURL = "EURL",
	EI = "EI",
	SAS = "SAS",
	SASU = "SASU",
	SNC = "SNC",
	SCS = "SCS",
	SCA = "SCA",
	SA = "SA",
	AUTRE = "AUTRE",
}
