import type { SubmitHandler } from "react-hook-form";
import { useEffect, useState } from "react";
import CustomCard from "@/components/CardComponent/CustomCard";
import { MobileFooter } from "@/components/MobileComponents/MobileFooter";
import { useCustomNavigate } from "@/hooks/customNavigate";
import { LefttPageSIRENInput } from "@/pages/4-CompanySIRENPage/components/LefttPageSIRENInput";
import useCompanyStore, { ValidationModeEnum } from "@/stores/useCompanyStore";
import useProgressStore from "@/stores/useProgressStore";
import useTurnoverStore from "@/stores/useTurnoverStore";
import { getCompnaySchemaByValidationMode } from "@/utils/getCompanySchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

import type { CompanyDto } from "@repos/ecom-dtos";

import SignificantSirenTargetCard from "./components/SignificantSirenTargetCard";

export enum ManualMode {
	AllDisabled = "allDisabled",
	AlwaysDisabled = "allwaysDisabled",
	IsManual = "isManual",
}

interface CompanySIRENPageProps {
	stepProps: number;
}

export const CompanySIRENPage = ({ stepProps }: CompanySIRENPageProps) => {
	const navigate = useCustomNavigate();
	const { incrementStep, setStep, setLastSubmittedStep } = useProgressStore(
		(state) => ({
			incrementStep: state.incrementStep,
			decrementStep: state.decrementStep,
			setStep: state.setStep,
			setLastSubmittedStep: state.setLastSubmittedStep,
		}),
	);

	const {
		isSirenOptional,
		setIsSirenOptional,
		isCompanyNotFound,
		setIsCompanyNotFound,
	} = useCompanyStore((state) => ({
		isSirenOptional: state.isSirenOptional,
		setIsSirenOptional: state.setIsSirenOptional,
		isCompanyNotFound: state.isCompanyNotFound,
		setIsCompanyNotFound: state.setIsCompanyNotFound,
	}));

	const { turnover } = useTurnoverStore();

	useEffect(() => {
		if (!turnover) {
			navigate("TURNOVER");
		}
	}, [turnover, navigate]);

	const [isManualMode, setIsManualMode] = useState<ManualMode>(
		ManualMode.AllDisabled,
	);

	const toggleMode = (value: ManualMode) => {
		setIsManualMode(value);
	};

	const toggleCompanyFound = () => {
		setIsCompanyNotFound(!isCompanyNotFound);
	};

	const toggleSirenOptional = () => {
		if (isSirenOptional === ValidationModeEnum.SIREN_Required) {
			setIsSirenOptional(ValidationModeEnum.SIREN_AND_NAF_Optional);
		} else if (isSirenOptional === ValidationModeEnum.SIREN_AND_NAF_Required) {
			setIsSirenOptional(ValidationModeEnum.SIREN_AND_NAF_Optional);
		} else {
			setIsSirenOptional(ValidationModeEnum.SIREN_Required);
		}
	};

	const { company, setCompany } = useCompanyStore((state) => ({
		company: state.company,
		setCompany: state.setCompany,
	}));

	const methods = useForm<CompanyDto>({
		defaultValues: {
			sirenNumber: company?.sirenNumber || "",
			name: company?.name || "",
			legalStatusLabel: company?.legalStatusLabel || "",
			address: company?.address || "",
			city: company?.city || "",
			postalCode: company?.postalCode || "",
			codeNaf: company?.codeNaf || "",
			legalStatusCode: company?.legalStatusCode || "",
			mainActivityCode: company?.codeNaf || "",
			mainActivityLabel: company?.mainActivityLabel || "",
			commune: company?.commune || "",
			siret: company?.siret || "",
		},
		resolver: zodResolver(getCompnaySchemaByValidationMode(isSirenOptional)),
		mode: "onChange",
	});

	const onSubmit: SubmitHandler<CompanyDto> = (data) => {
		incrementStep();
		navigate("COMPANY_ACTIVITY");
		const updatedCompany: CompanyDto = {
			...company,
			sirenNumber: data.sirenNumber,
			name: data.name,
			legalStatusLabel: data.legalStatusLabel,
			legalStatusCode: data.legalStatusCode,
			mainActivityCode: data.codeNaf,
			mainActivityLabel: data.mainActivityLabel,
			commune: data.commune,
			siret: data.siret,
			address: data.address,
			codeNaf: data.codeNaf,
			city: data.city,
			postalCode: data.postalCode,
		};
		setCompany(updatedCompany);
		setLastSubmittedStep(stepProps);
	};

	const handleBack = () => {
		navigate("SIMULATION_GUARANTEE");
	};

	useEffect(() => {
		setStep(stepProps);
	}, [stepProps, setStep]);

	return (
		<FormProvider {...methods}>
			<form onSubmit={methods.handleSubmit(onSubmit)}>
				<div className="flex h-full w-screen flex-1 flex-col items-center gap-5 lg:flex-row lg:gap-0">
					<div className="flex h-full w-full flex-1 flex-col items-center justify-center">
						<LefttPageSIRENInput
							enableNumSirenInput={isManualMode}
							onClickRadio={toggleMode}
							onToggleOptional={toggleSirenOptional}
							onToggleCompany={toggleCompanyFound}
						/>
					</div>
					<div className="mr-0 flex h-full w-full flex-1 items-center justify-center lg:mr-5">
						<CustomCard
							displayFooter={true}
							hideBackButton={false}
							className={"md:w-[85vw] md:max-w-[85vw]"}
							handleBack={handleBack}
						>
							<SignificantSirenTargetCard isManualMode={isManualMode} />
						</CustomCard>
					</div>
				</div>
				<MobileFooter />
			</form>
		</FormProvider>
	);
};
