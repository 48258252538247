import { Popover, PopoverTrigger } from "../ui/popover";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "../ui/tooltip";

interface PopupHintProps {
	tooltipLabel?: string;
	tooltipDescription?: string;
	children: React.ReactNode;
}
function PopupHint({
	tooltipLabel,
	tooltipDescription,
	children,
}: PopupHintProps) {
	return (
		<Popover>
			<TooltipProvider>
				<Tooltip>
					<TooltipTrigger asChild>
						<PopoverTrigger asChild>{children}</PopoverTrigger>
					</TooltipTrigger>
					<TooltipContent className="z-50 mb-1 w-80 rounded-lg bg-yellow">
						<p className="text-base font-bold text-black"> {tooltipLabel} </p>
						<p className="text-sm font-normal text-black">
							{tooltipDescription}
						</p>
						<div className="absolute -bottom-0 left-1/2 size-3 -translate-x-1/2 rotate-45 transform border-t border-yellow bg-yellow" />
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		</Popover>
	);
}

export default PopupHint;
