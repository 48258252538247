import type { ClassValue } from "clsx";
import infoIcon from "@/assets/infoIcon.svg";
import PopupHint from "@/components/shared/PopupHint";
import { cn } from "@/utils";

interface InfoInsuranceSelectedProps {
	children: React.ReactNode;
	icon: string;
	title: string;
	className?: ClassValue;
	hint: {
		title: string;
		description: string;
	};
}

export const InfoInsuranceSelected = ({
	children,
	icon,
	title,
	className,
	hint,
}: InfoInsuranceSelectedProps) => {
	return (
		<div
			className={cn(
				"w-full border-black border-opacity-10 pb-0 lg:border-b lg:pb-7",
				className,
			)}
		>
			<div className="flex items-center justify-between">
				<div className="flex items-center space-x-3">
					<img src={icon} alt={title} />
					<div className="flex items-center text-xs font-medium text-[#444B53] md:text-sm lg:text-md lg:text-black">
						{title}
						<PopupHint
							tooltipLabel={hint.title}
							tooltipDescription={hint.description}
						>
							<img src={infoIcon} className="ml-2 hidden size-5 lg:flex" />
						</PopupHint>
					</div>
				</div>
				{children}
			</div>
		</div>
	);
};
