import { create } from "zustand";
import { persist } from "zustand/middleware";

import type { CompanyDto } from "@repos/ecom-dtos";

export enum ValidationModeEnum {
	SIREN_Required = "sirenRequired",
	SIREN_AND_NAF_Required = "sirenAndNafRequired",
	SIREN_AND_NAF_Optional = "sirenAndNafOptional",
}

// Type of store
interface CompanyState {
	company: CompanyDto | null;
	setCompany: (company: CompanyDto | null) => void;
	isSirenOptional: ValidationModeEnum;
	setIsSirenOptional: (optional: ValidationModeEnum) => void;
	isCompanyNotFound: boolean;
	setIsCompanyNotFound: (notFound: boolean) => void;
	clearCompany: () => void;
}

// Create store
const useCompanyStore = create<CompanyState>()(
	persist(
		(set) => ({
			company: null,
			setCompany: (company: CompanyDto | null) => set({ company }),

			isSirenOptional: ValidationModeEnum.SIREN_Required,
			setIsSirenOptional: (optional: ValidationModeEnum) =>
				set({ isSirenOptional: optional }),

			isCompanyNotFound: false,
			setIsCompanyNotFound: (notFound: boolean) =>
				set({ isCompanyNotFound: notFound }),

			clearCompany: () =>
				set({
					company: null,
					isSirenOptional: ValidationModeEnum.SIREN_Required,
					isCompanyNotFound: false,
				}),
		}),
		{
			name: "company-storage",
			getStorage: () => localStorage,
		},
	),
);

export default useCompanyStore;
