import { ValidationModeEnum } from "@/stores/useCompanyStore";

import {
	companySchemaOptional,
	companySchemaRequired,
	restCompanySchemaOptional,
} from "@repos/ecom-dtos";

export const getCompnaySchemaByValidationMode = (
	validationMode: ValidationModeEnum,
) => {
	switch (validationMode) {
		case ValidationModeEnum.SIREN_Required:
			// initial check (only sirenNumber)
			return restCompanySchemaOptional;
		case ValidationModeEnum.SIREN_AND_NAF_Required:
			// isSirenOptional is false
			return companySchemaRequired;
		case ValidationModeEnum.SIREN_AND_NAF_Optional:
			// isSirenOptional is true
			return companySchemaOptional;
		default:
			// Provide a fallback if none of the cases match
			return restCompanySchemaOptional;
	}
};
