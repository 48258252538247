import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";

interface PopupMobileClickProps {
	tooltipTitle?: string;
	tooltipDescription?: string;
	children: React.ReactNode;
}

function PopupMobileClick({
	children,
	tooltipTitle,
	tooltipDescription,
}: PopupMobileClickProps) {
	return (
		<Popover>
			<PopoverTrigger>{children}</PopoverTrigger>
			<PopoverContent
				side="top"
				className="relative bottom-1 w-80 rounded-xl border-none bg-[#0F6DD4] px-6 text-sm font-bold text-white shadow-none"
			>
				<p>{tooltipTitle}</p>
				<p>{tooltipDescription}</p>
				<div className="absolute -bottom-1 left-1/2 size-3 -translate-x-1/2 rotate-45 transform border-t border-[#0F6DD4] bg-[#0F6DD4]" />
			</PopoverContent>
		</Popover>
	);
}

export default PopupMobileClick;
