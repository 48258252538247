import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import scanIcon from "@/pages/4-CompanySIRENPage/assets/scanIcon.svg";
import { cn, trpc } from "@/utils";
import { Spinner } from "react-activity";
import { useFormContext, useWatch } from "react-hook-form";

import "react-activity/dist/library.css";

import { useEffect, useState } from "react";
import TextInput from "@/components/shared/TextInput";
import { ManualMode } from "@/pages/4-CompanySIRENPage";
import useCompanyStore, { ValidationModeEnum } from "@/stores/useCompanyStore";

import type { CompanyDto } from "@repos/ecom-dtos";
import { sirenSchema } from "@repos/ecom-dtos";

interface LefttPageSIRENInputProps {
	enableNumSirenInput?: ManualMode;
	onClickRadio?: (value: ManualMode) => void;
	onToggleOptional?: () => void;
	onToggleCompany?: () => void;
}

export const LefttPageSIRENInput = ({
	enableNumSirenInput,
	onClickRadio,
	onToggleOptional,
	onToggleCompany,
}: LefttPageSIRENInputProps) => {
	const {
		isSirenOptional,
		setIsSirenOptional,
		isCompanyNotFound,
		setIsCompanyNotFound,
	} = useCompanyStore((state) => ({
		isSirenOptional: state.isSirenOptional,
		setIsSirenOptional: state.setIsSirenOptional,
		isCompanyNotFound: state.isCompanyNotFound,
		setIsCompanyNotFound: state.setIsCompanyNotFound,
	}));

	const { mutateAsync, isLoading, isError } =
		trpc.siren.getActivityFields.useMutation();

	const [showRadioGroup, setShowRadioGroup] = useState(false);

	const { setValue } = useFormContext<CompanyDto>();

	const siren = useWatch({ name: "sirenNumber" });

	useEffect(() => {
		const res = sirenSchema.safeParse(siren);
		if (res.success && enableNumSirenInput !== ManualMode.AlwaysDisabled) {
			const getAddress = async () => {
				try {
					const data = await mutateAsync({ SIREN: siren });

					setValue("address", data.headquarters.address);
					setValue("name", data.completeName);
					setValue("legalStatusLabel", data.legalStatus.label);
					setValue(
						"postalCode",
						data.headquarters.detailedAddress.postalCode || "",
					);
					setValue("city", data.headquarters.detailedAddress.city || "");
					setValue("siret", data.headquarters.siret);
					setValue("codeNaf", data.mainActivity.code);
					setValue("legalStatusCode", data.legalStatus.code);
					setValue("sirenNumber", data.siren ?? "");
					setValue("commune", data.headquarters.commune ?? "");
					setValue("mainActivityCode", data.mainActivity.code);
					setValue("mainActivityLabel", data.mainActivity.label);

					onClickRadio && onClickRadio(ManualMode.AlwaysDisabled);
					setShowRadioGroup(false);
					setIsSirenOptional(ValidationModeEnum.SIREN_AND_NAF_Required);
					setIsCompanyNotFound(false);
				} catch (e) {
					console.log(e);
					setShowRadioGroup(true);
				}
			};
			getAddress();
		}
		if (siren.length < 9 && enableNumSirenInput === ManualMode.AlwaysDisabled) {
			onClickRadio && onClickRadio(ManualMode.AllDisabled);
			setValue("address", "");
			setValue("name", "");
			setValue("legalStatusLabel", "");
			setValue("legalStatusCode", "");
			setValue("postalCode", "");
			setValue("city", "");
			setValue("siret", "");
			setValue("codeNaf", "");
			setValue("sirenNumber", "");
			setValue("commune", "");
			setValue("mainActivityCode", "");
			setValue("mainActivityLabel", "");
		}
	}, [siren, mutateAsync, enableNumSirenInput, onClickRadio, setValue]);

	useEffect(() => {
		if (siren.length === 9 && isError) {
			setShowRadioGroup(true);
		}
	}, [siren, isError]);

	const toggleOptional = () => {
		if (onToggleOptional) {
			onToggleOptional();
			setIsCompanyNotFound(false);
		}
	};

	const toggleCompany = () => {
		if (onToggleCompany) {
			onToggleCompany();
			if (isSirenOptional === ValidationModeEnum.SIREN_Required) {
				setIsSirenOptional(ValidationModeEnum.SIREN_AND_NAF_Optional);
			} else if (
				isSirenOptional === ValidationModeEnum.SIREN_AND_NAF_Required
			) {
				setIsSirenOptional(ValidationModeEnum.SIREN_AND_NAF_Optional);
			} else {
				setIsSirenOptional(ValidationModeEnum.SIREN_Required);
			}
		}
	};

	useEffect(() => {
		if (
			isCompanyNotFound ||
			isSirenOptional === ValidationModeEnum.SIREN_AND_NAF_Optional
		) {
			onClickRadio && onClickRadio(ManualMode.IsManual);
		} else {
			onClickRadio && onClickRadio(ManualMode.AllDisabled);
		}
	}, [isCompanyNotFound, isSirenOptional]);

	return (
		<div className="mt-3 flex w-5/6 flex-col items-start pl-0 lg:pl-8">
			<div className="hidden flex-row place-items-center gap-[29px] md:flex">
				<img src={scanIcon} />
				<p className="w-[60%] text-2xl font-bold leading-10 text-gray-800">
					Indiquez votre numéro SIREN
				</p>
			</div>
			<p className="mb-5 mt-4 hidden w-full text-sm md:text-md lg:flex">
				Ces informations sont essentielles pour évaluer votre éligibilité et
				personnaliser l&apos;offre qui vous sera proposée.
			</p>
			<div className="mt-0 w-full md:mt-8">
				<div className="relative w-full lg:mb-6">
					<div className="relative">
						<TextInput<CompanyDto>
							label="SIREN"
							placeholder=""
							name="sirenNumber"
							enableInput={true}
							inputClassName={"h-20 xl:h-20 xl:w-full border-blue-500"}
							labelClassName={"left-10"}
							maxLength={9}
							typeInput={"number"}
							required={
								isSirenOptional === ValidationModeEnum.SIREN_AND_NAF_Required
							}
						/>
						{isLoading && (
							<Spinner className="absolute right-10 top-[35%] text-primary" />
						)}
					</div>
				</div>
				<RadioGroup
					defaultValue="comfortable"
					className={cn(
						"mt-4 flex h-9 w-full items-center justify-center rounded-full bg-transparent px-8 md:mt-3 lg:h-11 lg:justify-start lg:px-20",
						isSirenOptional === ValidationModeEnum.SIREN_AND_NAF_Optional
							? "text-white md:bg-blue-500"
							: "text-black md:bg-[#FFDD8569]",
					)}
				>
					<div className="flex items-center space-x-2">
						<RadioGroupItem
							onClick={toggleOptional}
							className={cn(
								"h-4 rounded-md border-[#A5A5A5] md:h-6 md:rounded-full md:border-white",
								isSirenOptional === ValidationModeEnum.SIREN_AND_NAF_Optional
									? "bg-blue-500 text-white lg:bg-white lg:text-blue-500"
									: "bg-white text-white",
							)}
							value="optional"
							id="siren-optional"
						/>
						<Label
							htmlFor="siren-optional"
							className={cn(
								"cursor-pointer text-xs font-normal md:text-sm",
								isSirenOptional === ValidationModeEnum.SIREN_AND_NAF_Optional
									? "text-blue-500 underline md:text-white lg:no-underline"
									: "text-grayMobile lg:text-black",
							)}
						>
							Mon entreprise est en cours de création
						</Label>
					</div>
				</RadioGroup>
				{showRadioGroup && (
					<RadioGroup
						defaultValue="comfortable"
						className={cn(
							"mt-0 flex h-9 w-full items-center justify-center rounded-full bg-transparent px-8 lg:mt-2 lg:h-11 lg:justify-start lg:px-20",
							isCompanyNotFound
								? "text-white md:bg-blue-500"
								: "text-black md:bg-[#FFDD8569]",
						)}
					>
						<div className="flex items-center space-x-2">
							<RadioGroupItem
								onClick={toggleCompany}
								className={cn(
									`h-4 cursor-pointer rounded-md border-[#A5A5A5] md:h-6 md:rounded-full md:border-white ${isCompanyNotFound ? "bg-blue-500 text-white lg:bg-white lg:text-blue-500" : "bg-white text-white"} `,
								)}
								value="default"
								id="siren-not-found"
							/>
							<Label
								htmlFor="siren-not-found"
								className={cn(
									"cursor-pointer text-xs font-normal text-grayMobile md:text-sm",
									isCompanyNotFound
										? "text-blue-500 underline md:text-white lg:no-underline"
										: "text-grayMobile lg:text-black",
								)}
							>
								Je ne trouve pas mon entreprise
							</Label>
						</div>
					</RadioGroup>
				)}
			</div>
		</div>
	);
};
