import type { ClassValue } from "clsx";
import infoIcon from "@/assets/infoIcon.svg";
import PopupHint from "@/components/shared/PopupHint";
import { cn } from "@/utils";
import { calculateMonthlyPriceIncludingTax } from "@/utils/calculateMonthlyPriceIncludingTax";
import { formatNumber } from "@/utils/utilsDigital";

import { type CeilingOptionDto } from "@repos/ecom-dtos";

interface SingleInfoGuaranteeProps {
	icon: string;
	title: string;
	price: number;
	warrantyPrice: number | undefined;
	franchisePrice: number;
	franchiseColor: string;
	className?: ClassValue;
	optionsGuarantee: CeilingOptionDto[];
}

export const SingleInfoGuarantee = ({
	icon,
	title,
	price,
	warrantyPrice,
	franchisePrice,
	franchiseColor,
	className,
	optionsGuarantee,
}: SingleInfoGuaranteeProps) => {
	return (
		<div
			className={cn(
				"mb-8 flex w-full flex-col space-y-10 border-b border-black border-opacity-10 p-0 pb-6",
				className,
			)}
		>
			<div className="flex flex-row justify-between">
				<div className="flex w-4/6 items-center gap-3">
					<img className="size-8" src={icon} alt={title} />
					<h3 className="text-sm font-bold text-blue-500 lg:text-md">
						{title}
					</h3>
				</div>
				<p className="flex px-5 text-center text-sm font-bold text-blue-500 lg:text-md">
					+{price.toFixed(2)} €
				</p>
			</div>
			<div className="flex flex-col gap-4 space-y-4 pl-5 md:pl-28">
				{optionsGuarantee.length > 0 && (
					<div className="flex w-full flex-row justify-between">
						<div className="flex w-full flex-col items-start">
							<p className="text-sm font-medium text-black lg:text-md">
								Les sous garanties incluses
							</p>
							<ul className="ml-8 mt-4 w-full list-disc pr-6">
								{optionsGuarantee.map((item, index) => (
									<li key={`opt-${index}`}>
										<div className="flex flex-row items-center justify-between">
											<p className="flex px-1 text-center text-xs  text-black lg:text-sm">
												{item.label}
											</p>
											<p className="flex px-5 text-center text-xs font-bold text-blue-500 lg:text-sm">
												{calculateMonthlyPriceIncludingTax(
													item.priceBeforeTax,
													item.taxValue,
												)}{" "}
												€
											</p>
										</div>
									</li>
								))}
							</ul>
						</div>
					</div>
				)}
				<div className="flex flex-row justify-between">
					<div className="flex items-center">
						<p className="text-sm font-medium text-black lg:text-md">
							Limite de garantie (LOI)
						</p>
						<PopupHint
							tooltipLabel="Limite de garantie"
							tooltipDescription="C’est le plafond du remboursement prévu en cas de sinistre."
						>
							<img src={infoIcon} className="ml-2 size-5" />
						</PopupHint>
					</div>
					{warrantyPrice && (
						<p className="px-5 text-center text-sm font-bold text-black lg:text-md">
							{formatNumber(warrantyPrice)} €
						</p>
					)}
				</div>
				<div className="flex flex-row justify-between">
					<div className="flex items-center">
						<p className="text-sm font-medium text-black lg:text-md">
							Franchise
						</p>
						<PopupHint
							tooltipLabel="Franchise"
							tooltipDescription="La part du dommage, et/ou des frais, restant à la charge de l’assuré, et au-delà de laquelle s’exerce la garantie de l’assureur."
						>
							<img src={infoIcon} className="ml-2 size-5" />
						</PopupHint>
					</div>

					{/* 
						Use inline styles for background color as it's dynamic based on `franchiseColor` which might not be available at build time for Tailwind CSS.
					*/}
					<div
						style={{
							backgroundColor: `${franchiseColor ? franchiseColor : "#CCCCCC"}`,
						}}
						className={`rounded-xl px-10 py-3`}
					>
						<p className="text-sm font-bold text-black lg:text-md">
							{formatNumber(franchisePrice)} €
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
